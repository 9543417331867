import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  DNC_FETCH,
  DNC_ADD,
  DNC_DELETE,
  DNC_SET_FILTERS
} from '../actions';

export function* watchDncFetchRequest() {
  const action = DNC_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.dnc.grid_options.dnc.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/dnc/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchLDncFiltersRequest() {
  yield takeEvery(DNC_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(DNC_FETCH.requestAction({}))
  })
}

export function* watchDncAddRequest() {
  const action = DNC_ADD
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'post',
      url: `${API_BASE}/v1/dnc/add`
    },
    requestOptionsFromPayload: (payload) => {
      return {
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchDncDeleteRequest() {
  const action = DNC_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptions: {
      method: 'delete',
    },
    requestOptionsFromPayload: (payload) => {
      return {
        url: `${API_BASE}/v1/dnc/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchDncFetchRequest),
    fork(watchLDncFiltersRequest),
    fork(watchDncAddRequest),
    fork(watchDncDeleteRequest),
  ]);
}