import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import metricsSaga from './metrics/saga'
import towersSaga from './towers/saga'
import leadsSaga from './leads/saga'
import commsSaga from './comms/saga'
import eventsSaga from './events/saga'
import reportsSaga from './reports/saga'
import dncSaga from './dnc/saga'

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    metricsSaga(),
    towersSaga(),
    leadsSaga(),
    commsSaga(),
    eventsSaga(),
    reportsSaga(),
    dncSaga(),
  ]);
}
