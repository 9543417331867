import { REHYDRATE } from 'redux-persist';
import {
  DNC_FETCH,
  DNC_SET_FILTERS,
} from '../actions';

const INIT_STATE = {
  list: [],
  list_paging: { total_rows: 0, page_size: 25, page_indx: 0 },
  grid_options: {
    dnc: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {
      }
    }
  },
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DNC_FETCH.SUCCESS: {
      const { dncs, total_rows, page_size, page_indx } = action.payload
      return { ...state, list: dncs, list_paging: { total_rows, page_size, page_indx } }
    }
    case DNC_SET_FILTERS.REQUEST: {
      return { ...state, grid_options: {
        ...state.grid_options,
        dnc: {
          ...state.grid_options.dnc,
          filters: {
            ...state.grid_options.dnc.filters,
            ...action.payload,
          }
        },
      } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
